import { Atom, Cmp, Ctrl } from ":mods";
import { setSelectedStage } from "../store";
import { LOCALSTORAGE_KEYS } from "../const";

export const checkSelectedStage: Atom.Route.model.AGuardStep = {
  runOn: ["app_init", "first_load", "each_route"],
  loaderMsg: "loading local stage selected...",
  checker({ base }) {
    // console.warn("checkSelectedStage :: ", base);
    if (base !== Ctrl.Routes.CONST.BASE_ROUTES.student) {
      console.warn("checkSelectedStage is meant to be used only with /student route");
      return undefined;
    }
    const stageSelected = localStorage.getItem(LOCALSTORAGE_KEYS.student_selected_stage);
    console.log("checkSelectedStage stageSelected is ", stageSelected);
    if (!stageSelected || stageSelected === "undefined") {
      return {
        error: "no stage selected",
        navigate: {
          path: "",
        },
      };
    }
    // console.log("sat stage selected");
    setSelectedStage(stageSelected);
    return undefined;
  },
};
